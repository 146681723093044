.path {
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  animation: dash 5s cubic-bezier(0.96, 0.18, 0.83, 0.76) forwards;
  transition: 2.5s;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
